import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInYears,
  format,
  getTime,
  setHours,
  setMinutes,
  subDays,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
export const dateFormat = 'dd.MM.yyyy'
export const timezone = 'Europe/Copenhagen'

export const serializeDate = (dateValue: Date | string | number) => {
  const d = new Date(dateValue)
  return new Intl.DateTimeFormat('da-DK', { dateStyle: 'short' }).format(d)
}

export const getMinAndMaxDate = (arr?: number[]) => {
  if (!arr || !arr.length) return false

  const min = Math.min(...arr)
  const max = Math.max(...arr)

  if (min && max) return `${serializeDate(min)} - ${serializeDate(max)}`
  else return false
}

export const formatDate = (d: Date | string | number, overrideFormat?: string) => {
  const newDate = new Date(d)
  return formatInTimeZone(newDate, timezone, overrideFormat || dateFormat)
}

export const add120days = (d: Date | string | number) => {
  const newDate = new Date(d)
  return addDays(newDate, 120)
}

export const getEarliestAvailableWeekday = (d: Date | string | number, overrideFormat?: string) => {
  const newDate = new Date(d)
  const day = newDate.getDay()

  // Sunday, sub 2 dats
  if (day === 0) {
    return formatDate(subDays(newDate, 2), overrideFormat)

    // Saturday, sub 1 day
  } else if (day === 6) {
    return formatDate(subDays(newDate, 1), overrideFormat)

    // Weekday, day accepted
  } else {
    return formatDate(d, overrideFormat)
  }
}

export const getTimeFromDate = (d: Date | string | number) => {
  const newDate = new Date(d)
  return formatDate(newDate, 'HH:mm')
}

export const addTimeToDate = (d: Date | string | number, minutes: number = 30) => {
  const newDate = new Date(d)
  return addMinutes(newDate, minutes)
}

export const dateToUnix = (d: Date | string | number) => {
  const newDate = new Date(d)
  return getTime(newDate)
}

export const combineDateAndTime = (dateVar, timeVar): number => {
  const datetime = Number(timeVar)
  const date = new Date(datetime)
  const hh = date.getHours()
  const mm = date.getMinutes()
  let newDate = setHours(dateVar, hh)
  newDate = setMinutes(newDate, mm)
  const unix = dateToUnix(newDate)
  return unix
}

export const daysFromNow = (d: Date | string | number) => {
  const today = new Date()
  const newDate = new Date(d)
  const diff = differenceInDays(newDate, today)
  return diff
}

export const getAgeFromBirthdate = (d: Date | number): number => {
  const dateWithoutTime = format(d, 'yyyy-MM-dd')
  const age = differenceInYears(new Date(), new Date(dateWithoutTime))
  return age
}

export const generateDateRange = (startDate: Date, endDate: Date): Date[] => {
  const dateRange: Date[] = []

  let currentDate = startDate
  while (currentDate <= endDate) {
    dateRange.push(currentDate)
    currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
  }

  return dateRange
}
