import * as React from 'react'
import cn from 'classnames'
import { ServiceMessage } from '../types/serviceMessage'
import { formatDate } from '../utils/date'

type Props = {
  data: ServiceMessage | null
}

const colors = {
  INFO: {
    bg: 'bg-blue-200',
    text: 'text-blue-600',
    border: 'border-blue-300'
  },
  WARNING: {
    bg: 'bg-yellow-200',
    text: 'text-yellow-600',
    border: 'border-yellow-300'
  },
  ERROR: {
    bg: 'bg-red-200',
    text: 'text-red-600',
    border: 'border-red-300'
  }
}

const ServiceBanner = (props: Props) => {
  const { data } = props
  if (!data || !data.currentServiceStatus) return <React.Fragment />

  const color = colors[data.currentServiceStatus.status]

  return (
    <div className={`w-full flex px-8 py-4 gap-4 mb-4 mt-20 ${color.bg} ${color.border} border-y`}>
      <p className={`font-bold min-w-fit ${color.text}`}>[{formatDate(data.currentServiceStatus.from, 'dd.MM.yyyy HH:mm')}]</p>

      <p className={`items-center ${color.text}`} dangerouslySetInnerHTML={{ __html: data.currentServiceStatus.message }}></p>
    </div>
  )
}

export default ServiceBanner
